import React from "react";

// Sections
import Hero from "sections/home/hero";
import Participa from "sections/home/participa";
import ComoParticipar from "sections/home/como-participar";
import Why from "sections/home/por-que";

const Home = () => {
  return (
    <React.Fragment>
      <Hero />
      <Participa />
      <ComoParticipar />
      <Why />
    </React.Fragment>
  );
};

export default Home;
