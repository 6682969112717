import React from 'react'

// Libraries
import styled from 'styled-components'

// Utils
// import breakpoint from 'utils/breakpoints/'

const ButtonStyles = `
  display: inline-flex;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border-radius: 100px;
  box-sizing: border-box;
`

const StyledButtonLink = styled.a`
  ${ButtonStyles}
`

export const ButtonLink = (props) => {
  return <StyledButtonLink {...props}>{props.children}</StyledButtonLink>
}
