import React from "react";

// Libraries
import styled from "styled-components";
import { colors } from "utils/variables/";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

// Utils
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";

const StyledWhy = styled.section`
  margin-top: 64px;
  padding-bottom: 84px;

  ${breakpoint.medium`
    padding-bottom: 152px;
    margin-top: 156px;
  `}

  .pre__seleccionados {
    .title {
      text-transform: uppercase;
      color: ${colors.indiblue};
      font-weight: 700;
      margin-top: 16px;
      margin-bottom: 16px;

      ${breakpoint.small`
        margin-top: 20px;
    `}
    }

    ol {
      padding: 0;
      color: ${colors.midnight};
      font-size: 18px;
      line-height: 25px;
    }
  }

  .why__image {
    margin-top: 56px;
    text-align: center;

    ${breakpoint.small`
      margin-top: 0;
      text-align: left;
    `}
  }
`;

const Why = () => {
  const { file: image } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/por-que-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 352, quality: 100)
        }
      }
    }
  `);

  return (
    <StyledWhy>
      <Container>
        <div
          className="row align-items-center justify-content-between"
          id="participantes"
        >
          <div className="col-12 col-sm-8 col-md-7">
            <h3 className="mb-3 font-weight--500">
              ¡Participaron 62 proyectos de todos los rincones de Argentina!
            </h3>

            <div className="pre__seleccionados">
              <h4 className="title">LOS pre-seleccionados FUERON:</h4>
              <ol>
                <li>1. Meraki Sustentable</li>
                <li>2. Modesta </li>
                <li>3. Umami Setas.</li>
                <li>4. Eco-centros Bariloche</li>
                <li>5. Alternativa Humus</li>
                <li>6. Arte Latika</li>
                <li>7. Ayla Eco</li>
                <li>8. Cookieleles</li>
                <li>9. Boobamaras</li>
                <li>10. Treeco</li>
              </ol>
            </div>
          </div>

          <div className="why__image col-12 col-sm-4">
            <GatsbyImage image={getImage(image)} alt="Indi Green" />
          </div>
        </div>
      </Container>
    </StyledWhy>
  );
};

export default Why;
