import React from "react";

// Libraries
import styled from "styled-components";
import { colors } from "utils/variables/";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

// Utils
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";

const StyledComoParticipar = styled.section`
  padding: 48px 0;

  color: ${colors.midnight};

  ${breakpoint.medium`
    padding: 88px 0;
  `}

  .col-12 {
    padding: 0 16px !important;
  }

  ul {
    margin-bottom: 56px;
    font-size: 18px;
    line-height: 26px;
  }

  .image {
    max-width: 312px;

    ${breakpoint.medium`
      max-width: 100%;
      margin-left: 110px;
    `}
  }

  span {
    font-weight: 700;
  }
`;

const ComoParticipar = () => {
  const { file: image } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/como-participar-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 352, quality: 100)
        }
      }
    }
  `);

  return (
    <StyledComoParticipar id="requisitos" className="bg--cream">
      <Container>
        <div className="row align-items-center">
          <div className="col-12 col-sm-7 align-items-center">
            <h3 className="mb-4 font-weight--500">¿Qué van a recibir?</h3>

            <p>
              Se llevará a cabo un proceso de <span>branding express</span> que
              incluye un Brand Narrative Workshop y un servicio de diseño
              personalizado, ajustado a sus necesidades concretas, para impulsar
              todo lo relacionado a la identidad visual.
            </p>
          </div>

          <div className="col-12 col-sm-4 col-md-5 text-center text-md-left">
            <GatsbyImage
              image={getImage(image)}
              className="image"
              alt="Indi Green"
            />
          </div>
        </div>
      </Container>
    </StyledComoParticipar>
  );
};

export default ComoParticipar;
