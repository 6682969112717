import React from "react";

// Libraries
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

// Utils
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";
import { ButtonLink } from "components/buttons/";
import { graphql, useStaticQuery } from "gatsby";

const StyledHero = styled.section`
  padding: 107px 0 48px 0;

  ${breakpoint.medium`
    height: 704px;
    display: flex;
    align-items: center;
  `}

  .hero__image {
    ${breakpoint.small`
      order: 1;
    `}
  }

  h3 {
    max-width: 623px;
  }

  a {
    margin-top: 32px;
  }
`;

const Hero = () => {
  const { file: image } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/hero-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 448, quality: 100, formats: PNG)
        }
      }
    }
  `);

  return (
    <StyledHero className="bg--green">
      <Container>
        <div className="row align-items-center">
          <div className="hero__image col-12 col-sm-4 col-md-5 mb-4 mb-sm-0">
            <GatsbyImage
              image={getImage(image)}
              placeholder="blurred"
              alt="Indi Green"
            />
          </div>

          <div className="hero__content col-12 col-sm-8 col-md-7">
            <h1 className="mb-3 color--white">Identidad para el cambio</h1>
            <h3 className="color--stout font-weight--500">
              ¡Ya seleccionamos a nuestro ganador! Felicitaciones al equipo de
              Alternativa Humus.
            </h3>
            <ButtonLink
              href="#alt-humus"
              className="bg--white bg-hover--stout color--stout color-hover--white"
            >
              Aprendé más
            </ButtonLink>
          </div>
        </div>
      </Container>
    </StyledHero>
  );
};

export default Hero;
