import React from "react";

// Libraries
import styled from "styled-components";
import { colors } from "utils/variables/";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";

// Utils
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";

const StyledParticipa = styled.section`
  padding: 56px 0;

  color: ${colors.midnight};

  ${breakpoint.medium`
    padding: 120px 0;
  `}

  .col-12 {
    padding: 0 16px !important;
  }

  .participa__image {
    max-width: 352px;
    margin: 56px auto 0 auto;

    ${breakpoint.small`
      margin-top: 0;
    `}
  }

  span {
    font-weight: 700;
  }
`;

const Participa = () => {
  const { file: image } = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "home/participa-image.png" }) {
        childImageSharp {
          gatsbyImageData(width: 352, quality: 100)
        }
      }
    }
  `);

  return (
    <StyledParticipa id="alt-humus">
      <Container>
        <div className="row align-items-center">
          <div className="col-12 col-sm-8 col-md-8">
            <h3 className="mb-3 font-weight--500">
              ¿Qué es
              <span className="color--indiblue"> Alternativa Humus?</span>
            </h3>
            <p>
              Es un emprendimiento de Mendoza, Argentina, que busca crear
              <span>
                soluciones integrales sustentables para la gestión de residuos
                orgánicos
              </span>{" "}
              hogareños, industriales y empresariales. Incluye venta de
              composteras de diversas capacidades, lombrices y talleres.
            </p>
          </div>

          <div className="col-12 col-sm-4 text-center text-md-left">
            <div className="participa__image">
              <GatsbyImage image={getImage(image)} alt="Indi Green" />
            </div>
          </div>
        </div>
      </Container>
    </StyledParticipa>
  );
};

export default Participa;
